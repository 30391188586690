import { Button, Form, Input, Typography } from "antd";
import "antd/dist/antd.css";
import { Store } from "antd/lib/form/interface";
import React, { useEffect, useState } from "react";
import { LoginData, useLoginContext } from "../contexts/LoginContext";
import { fetchLogin } from "../services/PasswordServices";
import "./Login.css";

interface LoginFormData {
	username: string;
	password: string;
}

const { Text } = Typography;

export default function Login(props: any) {
	const loginContext = useLoginContext();

	const [errorMessage, setErrorMessage] = useState("");

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		},
	};

	async function onFormFinish(values: Store) {
		const formData = values as LoginFormData;
		try {
			const login = await fetchLogin(
				formData.username,
				formData.password
			);

			if (login.Token) {
				let loginData: LoginData = {
					isLoggedIn: true,
					token: login.Token,
					userId: login.Claims.userid,
				};
				loginContext.setLoginData!(loginData);
			} else {
				setErrorMessage("Password errata!");
			}
		} catch (e) {
			setErrorMessage("Errore di connessione.");
		}
	}

	// Focus the username textbox on loading
	const usernameInput = React.createRef<Input>();
	useEffect(() => {
		usernameInput.current?.focus();
	}, []);

	return (
		<div className="Login">
			<h1>FCS Passwords</h1>
			<Form
				{...formItemLayout}
				labelAlign="left"
				colon={false}
				onFinish={onFormFinish}
			>
				<Form.Item
					label="Username"
					name="username"
					rules={[{ required: true, message: "Inserire username!" }]}
				>
					<Input ref={usernameInput} />
				</Form.Item>
				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: "Inserire password!" }]}
				>
					<Input type="password" />
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						style={{ width: "100%" }}
					>
						Login
					</Button>
				</Form.Item>
				<Text id="wrongPassLabel" type="danger">
					{errorMessage}
				</Text>
			</Form>
		</div>
	);
}
