import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Popconfirm, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { memo } from "react";
import { MdContentCopy, MdVisibility } from "react-icons/md";
import { Password } from "../services/PasswordServices";
import "./PasswordsTable.css";

interface PasswordsTableProps {
	passwords: Password[];
	className?: string;
	readonly?: boolean;
	onEditPassword: (p: Password) => void;
	onDeletePassword: (p: Password) => void;
}

function copyPassword(p: Password) {
	function fallbackCopyTextToClipboard(text: string) {
		const textArea = document.createElement("textarea");
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand("copy");
		} catch (err) {
			console.error("Fallback: Oops, unable to copy", err);
		}

		document.body.removeChild(textArea);
	}

	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(p.Password);
		return;
	}
	navigator.clipboard.writeText(p.Password);
}

const PasswordsTable = (props: PasswordsTableProps) => {
	const columns = [
		{
			title: "Device",
			dataIndex: "Device",
			key: "Id",
			defaultSortOrder: "ascend",
			sorter: (a: Password, b: Password) =>
				a.Device > b.Device ? 1 : -1,
			showSorterTooltip: false,
		},
		{
			title: "Username",
			dataIndex: "Username",
			key: "Id",
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: "Descrizione",
			dataIndex: "Description",
			key: "Id",
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: "Password",
			dataIndex: "Password",
			key: "Id",
			width: "100px",
			responsive: ["md"],
			render: (value: any, record: Password) => <div>********</div>,
		},
		{
			title: "",
			key: "Id",
			width: "80px",
			render: (value: any, record: Password) => (
				<div className="password-icons">
					<Tooltip title={record.Password} trigger="click">
						<Button icon={<MdVisibility />} type="link" />
					</Tooltip>
					<Tooltip title="Password copiata!" trigger="click">
						<Button
							icon={<MdContentCopy />}
							type="link"
							onClick={() => copyPassword(record)}
						/>
					</Tooltip>
				</div>
			),
		},
		{
			title: "",
			key: "Id",
			width: "100px",
			render: (value: any, record: Password, i: number) => (
				<Space>
					<Button
						icon={<EditIcon />}
						type="link"
						onClick={() => props.onEditPassword(record)}
						disabled={props.readonly}
					/>
					<Popconfirm
						title="Eliminare?"
						onConfirm={() => props.onDeletePassword(record)}
						cancelText="Annulla"
						disabled={props.readonly}
					>
						<Button
							icon={<DeleteIcon />}
							danger
							type="link"
							disabled={props.readonly}
						/>
					</Popconfirm>
				</Space>
			),
		},
	] as ColumnsType<Password>;

	return (
		<Table
			dataSource={props.passwords}
			columns={columns}
			className={props.className}
			rowKey="Id"
			pagination={false}
			tableLayout="fixed"
		/>
	);
};

export default memo(
	PasswordsTable,
	(prevProps: PasswordsTableProps, nextProps: PasswordsTableProps) => {
		return (
			JSON.stringify(prevProps.passwords) ===
				JSON.stringify(nextProps.passwords) &&
			prevProps.readonly === nextProps.readonly
		);
	}
);
