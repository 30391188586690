import { createContext, useContext } from "react";

export interface LoginData {
	isLoggedIn: boolean;
	token?: string;
	userId?: string;
}

export interface LoginDataContext {
	loginData?: LoginData;
	setLoginData?: React.Dispatch<React.SetStateAction<LoginData>>;
}
export const LoginContext = createContext<LoginDataContext>({});

export function useLoginContext(): LoginDataContext {
	return useContext(LoginContext);
}
