import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Button, List, Popconfirm, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Password } from "../services/PasswordServices";
import "./PasswordsList.css";

interface PasswordsListProps {
	passwords: Password[];
	className?: string;
	readonly?: boolean;
	onEditPassword: (p: Password) => void;
	onDeletePassword: (p: Password) => void;
}

export default (props: PasswordsListProps) => {
	const [passwordVisible, setPasswordVisible] = useState<{
		[Id: string]: boolean;
	}>({ "0": false });

	useEffect(() => {
		props.passwords.forEach((p) => {
			passwordVisible[p.Id] = false;
		});
		setPasswordVisible({ ...passwordVisible });
	}, []);

	return (
		<List
			dataSource={props.passwords}
			renderItem={(password: Password) => (
				<List.Item>
					<div className="item-container">
						<div className="item-password">
							<h4>{password.Device}</h4>
							<div className="item-password-description">
								{password.Description}
							</div>
							<div className="item-password-username">
								{password.Username}
							</div>
							<div
								className={
									passwordVisible[password.Id]
										? "item-password-password-visible"
										: "item-password-password-invisible"
								}
							>
								{password.Password}
							</div>
						</div>
						<div className="item-buttons">
							<Space>
								<Button
									icon={
										passwordVisible[password.Id] ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)
									}
									type="link"
									onClick={() => {
										setPasswordVisible({
											...passwordVisible,
											[password.Id]: !passwordVisible[
												password.Id
											],
										});
									}}
								/>
								<Button
									icon={<EditIcon />}
									type="link"
									disabled={props.readonly}
									onClick={() =>
										props.onEditPassword(password)
									}
								/>
								<Popconfirm
									title="Eliminare?"
									onConfirm={() =>
										props.onDeletePassword(password)
									}
									cancelText="Annulla"
								>
									<Button
										icon={<DeleteIcon />}
										danger
										disabled={props.readonly}
										type="link"
									/>
								</Popconfirm>
							</Space>
						</div>
					</div>
				</List.Item>
			)}
		/>
	);
};
