import { Form, Input, Modal, Select } from "antd";
import { Store } from "antd/lib/form/interface";
import React, { useEffect } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Category, Password, UserGroup } from "../services/PasswordServices";
import "./PasswordModal.css";

export interface PasswordModalProps {
	visible: boolean;
	okText?: string;
	cancelText?: string;
	password?: Password;
	categories: Category[];
	userGroups: UserGroup[];
	onCancel?: () => void;
	onOk?: (p: Password) => void;
}

const { Option } = Select;

export default function PasswordModal(props: PasswordModalProps) {
	const [form] = Form.useForm();

	const { visible } = props;

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 16 },
	};

	const onFinish = (password: Store) => {
		props.onOk && props.onOk(password as Password);
	};

	const deviceRef = React.createRef<Input>();
	useEffect(() => {
		setTimeout(() => {
			deviceRef.current?.focus();
		}, 0);
	}, [visible]);

	return (
		<Modal
			visible={visible}
			okText={props.okText}
			cancelText={props.cancelText}
			onCancel={() => {
				props.onCancel && props.onCancel();
			}}
			onOk={() => {
				form.validateFields()
					.then(() => {
						form.submit();
					})
					.catch(() => null);
			}}
		>
			<Form
				{...layout}
				initialValues={{
					Category: props.categories[0].Name,
					GroupId: props.userGroups[0].id,
					...props.password,
				}}
				form={form}
				onFinish={onFinish}
			>
				<Form.Item
					label="Device"
					name="Device"
					rules={[{ required: true }]}
				>
					<Input ref={deviceRef} />
				</Form.Item>
				<Form.Item label="Descrizione" name="Description">
					<Input />
				</Form.Item>
				<Form.Item label="Username" name="Username">
					<Input autoComplete="new-password" />
				</Form.Item>
				<Form.Item label="Password" name="Password">
					<Input.Password
						autoComplete="new-password"
						iconRender={(visible) =>
							visible ? <MdVisibility /> : <MdVisibilityOff />
						}
					/>
				</Form.Item>
				<Form.Item label="Categoria" name="Category">
					<Select placeholder="Categoria">
						{props.categories.map((c) => (
							<Option value={c.Name} key={c.Id}>
								{c.Name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label="Gruppo" name="GroupId">
					<Select placeholder="Gruppo Utente">
						{props.userGroups.map((ug) => (
							<Option value={ug.id} key={ug.id}>
								{ug.name}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}
