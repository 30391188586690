import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import ClientsList from "./containers/ClientsList";
import Error from "./containers/Error";
import Main from "./containers/Main";
import NotFound from "./containers/NotFound";
import { useDataContext } from "./contexts/DataContext";
import { useLoginContext } from "./contexts/LoginContext";
import { Data, FetchData } from "./services/PasswordServices";

const { Sider, Content } = Layout;

enum DownloadStatus {
	"Loading",
	"Done",
	"Error",
}

export default function Routes() {
	const loginContext = useLoginContext();
	const dataContext = useDataContext();
	const [downloadStatus, setDownloadStatus] = useState(
		DownloadStatus.Loading
	);

	useEffect(() => {
		const fn = async () => {
			try {
				const data: Data = await FetchData(loginContext.loginData!);
				dataContext.setData!(data);
				setDownloadStatus(DownloadStatus.Done);
			} catch (e) {
				setDownloadStatus(DownloadStatus.Error);
			}
		};
		fn();
	}, [loginContext.loginData, dataContext.setData]);

	if (downloadStatus === DownloadStatus.Loading) {
		return (
			<div className="Main">
				<h1 className="lander">Caricamento in corso...</h1>
			</div>
		);
	} else if (downloadStatus === DownloadStatus.Done) {
		return (
			<Switch>
				<Route
					path={[`${process.env.PUBLIC_URL}/clienti`]}
					component={ClientsList}
				/>

				<Route
					path={[`${process.env.PUBLIC_URL}/:clifor_cod`]}
					component={Main}
				/>

				<Route
					path={[`${process.env.PUBLIC_URL}/`]}
					component={ClientsList}
				/>

				<Route component={NotFound} />
			</Switch>
		);
	} else {
		return <Error />;
	}
}
