import { createContext, useContext } from "react";

export interface OnlineContext {
	isOnline: boolean;
	setIsOnline?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OnlineContext = createContext<OnlineContext>({ isOnline: true });

export function useOnlineContext(): OnlineContext {
	return useContext(OnlineContext);
}
