import { createContext, useContext } from "react";
import { Data } from "../services/PasswordServices";

export interface DataContext {
	data?: Data;
	setData?: React.Dispatch<React.SetStateAction<Data>>;
}
export const DataContext = createContext<DataContext>({});

export function useDataContext(): DataContext {
	return useContext(DataContext);
}
