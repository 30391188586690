import { Checkbox, Input, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDataContext } from "../contexts/DataContext";
import "./ClientsList.css";
import Error from "./Error";

const { Search } = Input;

export default function ClientsList() {
	const dataContext = useDataContext();

	const [clientFilter, setClientFilter] = useState("");
	const [showAll, setShowAll] = useState(false);

	const searchRef = React.createRef<Input>();
	useEffect(() => {
		searchRef.current?.focus();
	}, []);

	if (dataContext.data && dataContext.data.clients) {
		const clients = showAll
			? dataContext.data!.easyCoClients!
			: dataContext.data!.clients!;
		return (
			<div className="clients-list">
				<Search
					ref={searchRef}
					className="search"
					onChange={(e) => setClientFilter(e.target.value)}
				/>
				<Checkbox onChange={(e) => setShowAll(e.target.checked)}>
					Mostra tutti
				</Checkbox>
				<Menu mode="vertical" className="menu">
					{clients
						.sort((a, b) =>
							a.clifor_desc < b.clifor_desc ? -1 : 1
						)
						.filter(
							(c) =>
								c.clifor_desc.includes(
									clientFilter.toUpperCase()
								) && c.clifor_cod !== "0"
						)
						.map((c, i) => {
							return (
								<Menu.Item
									className="menu-item"
									key={c.clifor_cod}
								>
									<Link to={c.clifor_cod}>
										{c.clifor_desc}
									</Link>
								</Menu.Item>
							);
						})}
				</Menu>
			</div>
		);
	} else {
		return <Error />;
	}
}
