import { Button, Layout, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./App.css";
import Login from "./containers/Login";
import { DataContext } from "./contexts/DataContext";
import { LoginContext, LoginData } from "./contexts/LoginContext";
import { OnlineContext } from "./contexts/OnlineContext";
import Routes from "./Routes";
import { Data } from "./services/PasswordServices";

function App() {
	const [loginData, setLoginData] = useState<LoginData>({
		isLoggedIn: false,
	});
	const [data, setData] = useState<Data>({});
	const [isOnline, setIsOnline] = useState(true);
	const [loginTimeoutHandler, setLoginTimeoutHandler] = useState<
		NodeJS.Timeout
	>();

	const history = useHistory();

	function handleLogout() {
		setLoginData({ ...loginData, isLoggedIn: false });
	}

	useEffect(() => {
		history.push(`${process.env.PUBLIC_URL}/`);
	}, [loginData.isLoggedIn]);

	useEffect(() => {
		// If user is not using the app it will log out after some time
		document.addEventListener("visibilitychange", () => {
			if (document.visibilityState === "hidden") {
				// Logout after 10 minutes
				const handler = setTimeout(() => {
					setLoginData({ ...loginData, isLoggedIn: false });
				}, 10 * 1000 * 60);
				setLoginTimeoutHandler(handler);
			} else if (document.visibilityState === "visible") {
				if (loginTimeoutHandler) clearTimeout(loginTimeoutHandler);
			}
		});
	}, []);

	return (
		<LoginContext.Provider value={{ loginData, setLoginData }}>
			{!loginData.isLoggedIn ? (
				<Login />
			) : (
				<div className="App container">
					<Layout className="app-layout">
						<PageHeader
							title={
								<Link to={`${process.env.PUBLIC_URL}/`}>
									FCS Passwords
								</Link>
							}
							extra={
								<Button type="text" onClick={handleLogout}>
									Logout
								</Button>
							}
							className="page-header"
						></PageHeader>
						<Layout className="page-content">
							<OnlineContext.Provider
								value={{ isOnline, setIsOnline }}
							>
								<DataContext.Provider value={{ data, setData }}>
									<Routes />
								</DataContext.Provider>
							</OnlineContext.Provider>
						</Layout>
					</Layout>
				</div>
			)}
		</LoginContext.Provider>
	);
}

export default App;
