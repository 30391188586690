import _ from "lodash";
import * as config from "../config";
import { LoginData } from "../contexts/LoginContext";

export interface Password {
	Id: string;
	Clifor_cod: string;
	Device: string;
	Description: string;
	Username: string;
	Password: string;
	Id_added_by: number;
	Added_timestamp: Date;
	Category: string;
	Synced: boolean;
	Deleted: boolean;
	GroupId: number;
}

export interface UserGroup {
	id: string;
	name: string;
}

export interface Category {
	Id: string;
	Name: string;
}

export interface Client {
	clifor_cod: string;
	clifor_desc: string;
}

export interface ClientPassword {
	[clifor_cod: string]: Password[];
}

export interface Data {
	clientPasswords?: ClientPassword;
	categories?: Category[];
	clients?: Client[];
	easyCoClients?: Client[];
	userGroups?: UserGroup[];
}

async function fetchApi(
	url: string,
	token: string,
	method = "GET",
	body?: any
) {
	return await fetch(url, {
		headers: {
			Authorization: "Bearer " + token,
		},
		method: method,
		body: JSON.stringify(body),
	});
}

export async function FetchData(loginData: LoginData): Promise<Data> {
	const userToken = loginData.token!;

	const getPasswords = fetchApi(config.API_PASSWORDS_URL, userToken);
	const getUserGroups = fetchApi(
		config.API_GROUPS_URL + "/" + loginData.userId,
		userToken
	);
	const getClients = fetchApi(config.API_CLIENTS_URL, userToken);
	const getEasyCoClients = fetchApi(config.API_EASYCOCLIENTS_URL, userToken);
	const getCategories = fetchApi(config.API_CATEGORIES_URL, userToken);

	const [
		passwordsRes,
		userGroupsRes,
		clientsRes,
		easyCoClientsRes,
		categoriesRes,
	] = await Promise.all([
		getPasswords,
		getUserGroups,
		getClients,
		getEasyCoClients,
		getCategories,
	]);

	const userGroups: UserGroup[] = await userGroupsRes.json();
	const categories: Category[] = await categoriesRes.json();
	const easyCoClients: Client[] = await easyCoClientsRes.json();
	const clients: Client[] = await clientsRes.json();
	const passwords: Password[] = await passwordsRes.json();

	// Remove deleted passwords
	_.remove(passwords, (p) => p.Deleted);
	const clientPasswords: ClientPassword = _.groupBy(passwords, "Clifor_cod");
	// Remove duplicates from EasyCo
	const goodEasyCoClients: Client[] = _.uniqBy(easyCoClients, "clifor_cod");

	const data: Data = {
		clients,
		userGroups,
		easyCoClients: goodEasyCoClients,
		clientPasswords,
		categories,
	};

	return data;
}

export async function NewPassword(
	password: Password,
	loginData: LoginData
): Promise<Password> {
	if (!loginData.token) {
		throw "Token missing";
	}

	const postPassword = await fetchApi(
		config.API_PASSWORDS_URL,
		loginData.token,
		"POST",
		password
	);

	return await postPassword.json();
}

export async function EditPassword(
	password: Password,
	loginData: LoginData
): Promise<Password> {
	if (!loginData.token) {
		throw "Token missing";
	}

	const putPassword = await fetchApi(
		config.API_PASSWORDS_URL + `/${password.Id}`,
		loginData.token,
		"PUT",
		password
	);

	return await putPassword.json();
}

export async function DeletePassword(
	password: Password,
	loginData: LoginData
): Promise<Password> {
	if (!loginData.token) {
		throw "Token missing";
	}

	const delPassword = await fetchApi(
		config.API_PASSWORDS_URL + `/${password.Id}`,
		loginData.token,
		"DELETE",
		password
	);

	return await delPassword.json();
}

export async function NewClient(
	client: Client,
	loginData: LoginData
): Promise<Client[]> {
	if (!loginData.token) {
		throw "Token missing";
	}

	const postClient = await fetchApi(
		config.API_CLIENTS_URL,
		loginData.token,
		"POST",
		client
	);

	return await postClient.json();
}

export async function fetchLogin(
	username: string,
	password: string
): Promise<any> {
	const loginData = new FormData();
	loginData.append("username", username);
	loginData.append("password", password);

	let res = await fetch(config.API_LOGIN_URL, {
		method: "POST",
		body: loginData,
	});

	return await res.json();
}
