import { Button, Input, Select } from "antd";
import "antd/dist/antd.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteProps } from "react-router-dom";
import PasswordModal from "../components/PasswordModal";
import PasswordsList from "../components/PasswordsList";
import PasswordsTable from "../components/PasswordsTable";
import { useDataContext } from "../contexts/DataContext";
import { useLoginContext } from "../contexts/LoginContext";
import { useOnlineContext } from "../contexts/OnlineContext";
import {
	DeletePassword,
	EditPassword,
	NewClient,
	NewPassword,
	Password,
} from "../services/PasswordServices";
import "./Main.css";

const { Option } = Select;
const { Search } = Input;

interface MainProps extends RouteProps {
	match: {
		params: {
			clifor_cod: string;
		};
	};
}

const passwordSorter = (a: Password, b: Password): number => {
	return a.Device > b.Device ? 1 : -1;
};

export default function Main(props: MainProps) {
	const loginContext = useLoginContext();
	const dataContext = useDataContext();
	const onlineContext = useOnlineContext();

	const [tableFilter, setTableFilter] = useState("");
	const [category, setCategory] = useState("");
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [selectedPassword, setSelectedPassword] = useState({} as Password);

	const clifor_cod = props.match.params.clifor_cod;

	function fetchErrorHandler(e: Error) {
		onlineContext.setIsOnline!(false);
	}

	const onEditPassword = useCallback(
		(p) => {
			setSelectedPassword(p);
			setShowPasswordModal(true);
		},
		[setSelectedPassword, setShowPasswordModal]
	);

	const passwords = useMemo(() => {
		const clientPasswords = dataContext.data!.clientPasswords![clifor_cod];
		if (clientPasswords) {
			return clientPasswords
				.filter((p) => !p.Deleted)
				.filter(
					(p) =>
						p.Device.toLowerCase().includes(
							tableFilter.toLowerCase()
						) ||
						p.Description.toLowerCase().includes(
							tableFilter.toLowerCase()
						) ||
						p.Username.toLowerCase().includes(
							tableFilter.toLowerCase()
						)
				)
				.filter((p) => (category ? p.Category === category : true));
		} else {
			return [];
		}
	}, [
		tableFilter,
		JSON.stringify(dataContext.data?.clientPasswords![clifor_cod]),
		category,
	]);

	async function onSavePassword(password: Password) {
		password.Clifor_cod = clifor_cod;
		password = { ...selectedPassword, ...password };

		let pass: Password;

		// If we are editing the password we have a not-empty selectedPassword
		if (selectedPassword.Device) {
			try {
				pass = await EditPassword(password, loginContext.loginData!);

				const passIndex = dataContext.data?.clientPasswords![
					clifor_cod
				].findIndex((p) => p.Id === selectedPassword.Id);

				dataContext.data!.clientPasswords![clifor_cod][
					passIndex!
				] = pass;
			} catch (e) {
				fetchErrorHandler(e);
			}
		} else {
			try {
				if (!dataContext.data?.clientPasswords![clifor_cod]) {
					const selectedClient = dataContext.data?.easyCoClients!.filter(
						(c) => c.clifor_cod === clifor_cod
					)[0];
					if (selectedClient) {
						const client = (
							await NewClient(
								selectedClient,
								loginContext.loginData!
							)
						)[0];
						dataContext.data?.clients?.push(client);
					} else {
						throw "Client doesn't exist";
					}
				}
				pass = await NewPassword(password, loginContext.loginData!);

				// If the client is new it isn't present in the array
				if (dataContext.data?.clientPasswords![clifor_cod]) {
					dataContext.data?.clientPasswords![clifor_cod].push(pass);
				} else {
					dataContext.data!.clientPasswords![clifor_cod] = [pass];
				}
			} catch (e) {
				fetchErrorHandler(e);
			}
		}
		setShowPasswordModal(false);
	}

	async function onDeletePassword(password: Password) {
		try {
			const pass = await DeletePassword(
				password,
				loginContext.loginData!
			);

			const passIndex = dataContext.data?.clientPasswords![
				clifor_cod
			].findIndex((p) => p.Id === password.Id);

			dataContext.data!.clientPasswords![clifor_cod][passIndex!] = pass;
			// Force render of table
			dataContext.setData!({ ...dataContext.data! });
		} catch (e) {
			fetchErrorHandler(e);
		}
	}

	const searchRef = React.createRef<Input>();
	useEffect(() => {
		searchRef.current?.focus();
	}, []);

	const clientName =
		dataContext.data?.clients?.find((c) => c.clifor_cod === clifor_cod)
			?.clifor_desc ||
		dataContext.data?.easyCoClients?.find(
			(c) => c.clifor_cod === clifor_cod
		)?.clifor_desc;

	return (
		<div className="content">
			<div className="menu-bar">
				<h1>{clientName}</h1>
				<div className="filter-bar">
					<Search
						ref={searchRef}
						className="search"
						onChange={(e) => setTableFilter(e.target.value)}
					/>
					<Select
						className="select-category"
						onChange={(v) => setCategory(v.toString())}
						placeholder="Categoria"
					>
						<Option value="">Tutte le categorie</Option>
						{dataContext.data?.categories?.map((c) => (
							<Option value={c.Name} key={c.Id}>
								{c.Name}
							</Option>
						))}
					</Select>
				</div>
				<div>
					<Button
						type="primary"
						onClick={(e) => {
							setSelectedPassword({} as Password);
							setShowPasswordModal(true);
						}}
						className="button-new-password"
						disabled={!onlineContext.isOnline}
					>
						Nuova Password
					</Button>
				</div>
			</div>

			{window.innerWidth > 768 ? (
				<PasswordsTable
					className="password-table"
					passwords={passwords}
					onEditPassword={onEditPassword}
					onDeletePassword={onDeletePassword}
					readonly={!onlineContext.isOnline}
				/>
			) : (
				<PasswordsList
					className="password-list"
					passwords={passwords.sort(passwordSorter)}
					onEditPassword={onEditPassword}
					onDeletePassword={onDeletePassword}
					readonly={!onlineContext.isOnline}
				/>
			)}

			<PasswordModal
				key={selectedPassword.Id}
				visible={showPasswordModal}
				okText="Salva"
				cancelText="Annulla"
				onCancel={() => setShowPasswordModal(false)}
				onOk={onSavePassword}
				password={selectedPassword}
				categories={dataContext.data?.categories!}
				userGroups={dataContext.data?.userGroups!}
			/>
		</div>
	);
}
